<template>
  <!-- details modal  -->
  <b-modal
    size="lg"
    scrollable
    :id="id"
    :title="title"
    @ok="handleOk"
    @show="resetModal"
    @shown="onShown"
  >
    <div class="d-block text-center"></div>

    <form class="form" novalidate="novalidate" id="add_claim_form">
      <b-form-group
        id="fieldset-1"
        label="Opisz swój problem"
        label-for="description"
      >
        <b-form-textarea
          id="textarea"
          v-model="description"
          name="description"
          ref="description"
          placeholder="opisz swój problem"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="fieldset-1"
        label="Wybierz powód z listy"
        label-for="reason"
      >
        <b-form-select
          v-model="reason"
          name="reason"
          ref="reason"
          :options="claimreasons.items"
        ></b-form-select>
      </b-form-group>
    </form>
  </b-modal>
  <!-- end of details modal -->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  name: "AddClaimModal",
  props: {
    id: String,
    title: String
  },
  data() {
    return {
      reason: null,
      description: "",
      claimreasons: {
        items: [
          { value: null, text: "Wybierz" },
          { value: "BŁĘDNE_NALICZENIE", text: "Błędnie naliczono punkty" },
          { value: "BRAK_WYSYŁKI", text: "Zamówiona nagroda nie dotarła" },
          {
            value: "USZKODZONA_NAGRODA",
            text: "Zamówiona nagroda dotarła uszkodzona"
          },
          { value: "INNY", text: "Inny" }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.claimForm.validate().then(v => {
        if (v === "Valid") {
          ApiService.post("/claim/add", {
            description: this.description,
            reason: this.reason
          }).then(() => {
            this.refreshClaims();
            this.$nextTick(() => {
              this.$bvModal.hide(this.$props.id);
            });
          });
        }
      });
    },
    resetModal() {
      this.reason = null;
      this.description = "";
    },

    refreshClaims() {
      ApiService.get("/claims").then(response => {
        const claims = response.data;
        this.$emit("refreshData", claims);
      });
    },
    onShown() {
      const claim_form = KTUtil.getById("add_claim_form");

      this.claimForm = formValidation(claim_form, {
        fields: {
          description: {
            validators: {
              notEmpty: {
                message: "Proszę opisać swój problem"
              },
              blank: {}
            }
          },
          reason: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać powód reklamacji"
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    }
  }
};
</script>
