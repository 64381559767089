<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Reklamacje</b> w tym miejscu znajdują się Twoje zgłoszone reklamacje
        dotyczące błędnie naliczonych punktów, wszelkich innych kwestii, z
        którymi się nie zgadzasz i chcesz nas o tym poinformować.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Lista zgłoszonych reklamacji'"
          v-if="state === 'loaded'"
        >
          <template v-slot:toolbar-action>
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="showModal()"
              >
                dodaj <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                ref="claimstable"
                responsive
                striped
                hover
                :items="claims.items"
                :fields="claims.fields"
                :tbody-tr-class="rowClass"
                @row-clicked="rowClickHandler"
                :filter="filter"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mb-1"
                    @click="deleteClaim(row.item)"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>

    <template>
      <b-modal
        size="lg"
        scrollable
        ref="claimDetail"
        :title="'Status: ' + claim.status"
      >
        <div class="d-block text-left">
          <span>Typ: </span>
          <b-badge variant="primary">{{ claim.reason }}</b-badge>
        </div>

        <hr />

        <b-form-group id="fieldset-1" label="Treść" label-for="description">
          <b-row>
            <b-col cols="12">
              <b-form-textarea
                :value="claim.description"
                :disabled="true"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          id="fieldset-1"
          label="Odpowiedź Administratora"
          label-for="description"
        >
          <b-row>
            <b-col cols="12">
              <b-form-textarea
                :value="claim.response"
                :disabled="true"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </b-form-group>
      </b-modal>
    </template>

    <AddClaimModal
      :id="id"
      :title="'Nowa Reklamacja'"
      @refreshData="onSave"
    ></AddClaimModal>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import AddClaimModal from "@/view/pages/customer/claims/AddClaimModal.vue";
import Swal from "sweetalert2";

export default {
  name: "Claims",
  data() {
    return {
      state: "loading",
      claims: {
        fields: [
          {
            key: "date",
            label: "Data",
            sortable: true
          },
          {
            key: "reason",
            label: "Powód Reklamacji",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje"
          }
        ],
        items: []
      },
      claim: {},
      id: "addClaim",
      filter: null
    };
  },
  components: {
    KTCodePreview,
    AddClaimModal
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "ODRZUCONA") return "table-danger";
      if (item.status === "ZAAKCEPTOWANA") return "table-success";
    },

    rowClickHandler(record) {
      this.$refs.claimDetail.show();
      this.claim = record;
    },

    showModal() {
      this.$bvModal.show("addClaim");
    },

    onSave(claims) {
      this.claims.items = claims;
    },

    deleteClaim(row) {
      Swal.fire({
        title: "Czy napewno chcesz usunąć wpis?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009136",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/claim/" + row.id).then(() => {
            this.getClaims();
          });
        }
      });
    },

    getClaims() {
      ApiService.get("/claims").then(response => {
        this.claims.items = response.data;
        this.state = "loaded";
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Moje reklamacje" },
      { title: "" }
    ]);

    this.getClaims();
  }
};
</script>
